import api from "./api/api";

export function login(data) {
  return api.post("admin/admin-login", data);
}

export function adminCount() {
  return api.get("admin/count");
}

export function register(data) {
  return api.post("admin/create-admin", data);
}

export function forgotPassword(data) {
  return api.post("admin/forgot-password", data);
}

export function resetPassword(data, token) {
  return api.put(`admin/password/${token}`, data);
}
