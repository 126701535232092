import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setToken,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { login } from "services/authServices";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { token, signedIn, expired } = useSelector(
    (state) => state.auth.session
  );

  const signIn = async (data) => {
    try {
      const resp = await login(data);
      // console.log("useAuth", resp)

      if (resp?.data?.data?.token) {
        const token = resp?.data?.data?.token;
        // console.log("token", token)
        dispatch(setToken(token));
        dispatch(onSignInSuccess(token));

        if (resp.data.data) {
          const userData = resp.data.data;

          dispatch(
            setUser({
              authority: userData.role ? userData.role : "guest",
              email: userData?.email ? userData.email : "test@gmail.com",
            })
          );
        }

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        // console.log("redirectUrl: ", redirectUrl);

        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: "login done",
        };
      } else {
        return {
          status: "failed",
          message: "Failed to log in, Please Check Your Credentials",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  const checkAuthenticated = () => {
    if (expired > new Date().getTime()) {
      return true;
    } else {
      handleSignOut();
      return false;
    }
  };
  return {
    authenticated: token && signedIn === true && checkAuthenticated(),
    signIn,
    signOut,
  };
}

export default useAuth;
